<script>
// @ts-nocheck

import Nav from "$lib/components/nav.svelte";
import Footer from "$lib/components/footer.svelte";
import SvgGraphics from "$lib/components/svg-graphics.svelte";
import { afterNavigate, beforeNavigate } from "$app/navigation";
import { fade } from "svelte/transition";
import { cubicOut } from "svelte/easing";
import { onMount } from "svelte";
import { page } from "$app/stores";

export let data;

let ready = true;

$: loading = false;
$: pathName = $page.url.pathname;

function scrollToSection(pHash, pBehavior) {
  let element = document.querySelector(pHash);
  let headerOffset = document.querySelector("header").offsetHeight - 5;
  let elemPosition = element.getBoundingClientRect().top;
  let offsetPosition = elemPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: pBehavior,
  });
}

afterNavigate((navigation) => {
  loading = false;

  if (!navigation.to.url.hash) {
    window.scrollTo({ top: 0 });
  }

  if ($page.url.hash) {
    scrollToSection($page.url.hash, "auto");
  }
});

beforeNavigate(async ({ to, from, cancel }) => {
  let destinationURL = to.url.origin;
  let websiteURL = window.location.origin;

  if (destinationURL === websiteURL) {
    loading = true;
  } else {
    loading = false;
  }
});

onMount(() => {
  document.querySelectorAll('a[href^="/#"]').forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      if (pathName === "/") {
        e.preventDefault();
        scrollToSection(this.getAttribute("href").replace("/", ""), "smooth");
      }
    });
  });

  Weglot.initialize({
    api_key: "wg_600d6ddbe89d628fd63f5f3ecae51e6f0",
  });
});
</script>

<Nav {...data.navigation} />

{#if loading}
  <div
    class="loading-screen"
    in:fade={{ duration: 200, easing: cubicOut }}
    out:fade={{ duration: 300, easing: cubicOut }}
  />
{/if}

<main data-sveltekit-prefetch>
  <slot />
</main>

<Footer section={data.footer} bind:showFooter={ready} />

<SvgGraphics />

{@html data.siteSettings.analytics}

<style>
@import "../styles/base.css";

.loading-screen {
  position: fixed;
  background-color: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.weglot-container {
  width: 100%;
  margin-left: auto;
  position: absolute;
  margin-right: auto;
  left: 0;
  display: flex;
  right: 0;
  position: fixed;
  z-index: 124;
  bottom: 3rem;
  justify-content: flex-end;
  align-items: flex-end;
  pointer-events: none;
}

.weglot-container a {
  pointer-events: all;
}

@media screen and (min-width: 768px) {
  .weglot-container {
    bottom: 6rem;
  }
}

.wg-default .country-selector {
  position: relative;
}

.wg-default .country-selector {
  right: 1.5rem !important;
  bottom: 2rem !important;
}

@media screen and (min-width: 768px) {
  .wg-default .country-selector {
    right: 2rem !important;
  }
}
</style>
