<script>
import { onMount } from 'svelte';

export let section;

onMount(() => {
  console.log();
});
</script>

{#if section.footer_nav}
  <footer>
    <div class="footer__inner">
      <div class="footer__content">
        <div class="footer__logo">
          <a href="/" title="Varo hoome" rel="internal"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              id="Layer_1"
              x="0"
              y="0"
              version="1.1"
              viewBox="0 0 420 160"
              xml:space="preserve"
            >
              <title>Varo</title>
              <path
                d="M6.2 42.9c-1-2.1.3-4.2 2.7-4.2h21.4c1.3 0 2.4 1 2.7 1.8l26.6 58.8h1l26.6-58.8c.3-.8 1.5-1.8 2.7-1.8h21.4c2.4 0 3.7 2.1 2.7 4.2L63.5 151.4c-.5 1-1.5 1.8-2.7 1.8h-1.6c-1.3 0-2.3-.8-2.7-1.8L6.2 42.9zM322.2 38.7c-22.6 0-41.9 12.7-51.5 31.4-2.5-16.8-17.1-29.8-34.8-29.8h-48.2c-1.8 0-3.1 1.4-3.1 3.1v88.8c0 1.3-1.7 1.6-2.2.5l-42.9-92.2c-.5-1-1.6-1.8-2.7-1.8h-1.6c-1.1 0-2.3.8-2.7 1.8L82.1 148.9c-1 2.1.3 4.2 2.7 4.2h17.7c3.2 0 5-1.9 5.8-3.9l5.5-12.2c.2-.4.6-.7 1.1-.7h42.3c.5 0 .9.3 1.1.7l5.5 12c1.5 3.1 2.7 4 5.6 4h36.3c2.3 0 4.2-1.9 4.2-4.2v-38.7c0-.7.5-1.2 1.2-1.2h9.2c.4 0 .9.3 1.1.6l21.1 42.1c.3.6 1.3 1.5 2.6 1.5h21.4c2.7 0 3.9-2.6 2.7-4.7l-21.5-39.8c-.3-.6-.1-1.4.6-1.6 6.5-2.8 12-7 15.9-12.2 0 .7-.1 1.4-.1 2.1 0 32.2 25.6 57.9 57.9 57.9 32.2 0 58-25.6 58-57.9s-25.6-58.2-57.8-58.2zm-175.7 75.7h-20.8c-.9 0-1.4-.9-1.1-1.7l11.3-24.9h.2l11.5 24.9c.3.9-.3 1.7-1.1 1.7zm87.4-25.9h-22.7c-.7 0-1.2-.5-1.2-1.2V64.5c0-.7.5-1.2 1.2-1.2h22.7c6.6 0 12.2 5.5 12.2 12.2s-5.6 13-12.2 13zm88.3 40.5c-17.6 0-32.1-14.5-32.1-32.1 0-17.7 14.5-32.4 32.1-32.4 17.7 0 32.2 14.7 32.2 32.4 0 17.5-14.5 32.1-32.2 32.1zM391.1 146h-2c-.2 0-.4-.2-.4-.4v-1.5c0-.2.2-.4.4-.4h7.5c.2 0 .4.2.4.4v1.5c0 .2-.2.4-.4.4h-2.1c-.2 0-.4.2-.4.4v6.4c0 .2-.2.4-.4.4h-1.9c-.2 0-.4-.2-.4-.4v-6.4c.1-.2-.1-.4-.3-.4zm7.7-2.3h2.2c.1 0 .3.1.3.2l1.8 2.9c.2.2.5.2.7 0l1.8-2.9c.1-.1.2-.2.3-.2h2.2c.2 0 .4.2.4.4v8.7c0 .2-.2.4-.4.4h-1.8c-.2 0-.4-.2-.4-.4v-5.2l-2.4 3.8h-.1l-2.4-3.8v5.2c0 .2-.2.4-.4.4h-1.8c-.2 0-.4-.2-.4-.4v-8.7c0-.2.2-.4.4-.4z"
                style="fill:#09638a;"
              />
              <g>
                <path
                  d="M392.1 102.3c-2.6 0-4.6-2-4.7-4.6-1.1-35.7-29.8-64.5-65.4-65.6-2.6-.1-4.6-2.1-4.6-4.7V10c0-2.6 2.1-4.7 4.7-4.7 53.2 1.1 91.1 39 92.1 92.3 0 1.3-.5 2.5-1.3 3.4-.9.9-2.1 1.4-3.4 1.4h-17.4v-.1zM321.5 102.3c-2.3 0-4.2-1.9-4.2-4.2V75.9c0-2.2 1.8-4 3.9-4h.2c14.1.8 25.3 12.1 26.1 26.2.1 1.1-.3 2.1-1.1 2.9-.7.8-1.8 1.2-2.9 1.2l-22 .1z"
                  style="fill:#00abe8;"
                />
              </g>
            </svg></a
          >
        </div>

        <ul class="footer__navigation">
          {#each section.footer_nav.items as link}
            <li class="footer__navigation__item">
              {#if link.navigationItemUrl.linkType == 'internal'}
                <a
                  rel="internal"
                  class="footer__navigation__link"
                  href="/{link.navigationItemUrl.internalLink.handle.current}"
                  title="{link.text}}"
                >
                  <span>{link.text}</span>
                </a>
              {/if}

              {#if link.navigationItemUrl.linkType == 'external'}
                <a
                  href={link.navigationItemUrl.href}
                  target={link.navigationItemUrl.blank ? '_blank' : null}
                  rel="external"
                  class="footer__navigation__link"
                  title="Go to external link at {link.navigationItemUrl.href}"
                >
                  <span>{link.text}</span>
                </a>
              {/if}
            </li>
          {/each}
        </ul>
      </div>

      <div class="footer__credit">
        <p>{section.credit}</p>
      </div>
    </div>
  </footer>
{/if}

<style>
footer {
  background-color: transparent;
}
footer .footer__inner {
  border: 18px solid #fff;
  padding: 2rem;
  padding-bottom: 0;
}
@media only screen and (min-width: 768px) {
  footer .footer__inner {
    padding-bottom: 0;
    padding: 0;
    padding-top: 1.125rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
}
@media only screen and (min-width: 1000px) {
  footer .footer__inner {
    border: 24px solid #fff;
    max-width: 71rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    margin-right: auto;
    margin-left: auto;
  }
}
footer .footer__logo {
  width: 6.5rem;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2.25rem;
}
@media only screen and (min-width: 768px) {
  footer .footer__logo {
    width: 7rem;
    margin-left: 0;
    margin-right: 2.25rem;
    margin-bottom: 0;
  }
}
footer .footer__logo svg {
  fill: #0491bc;
}
footer .footer__navigation {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 768px) {
  footer .footer__navigation {
    flex-direction: row;
    justify-content: space-between;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    margin-bottom: 2.25rem;
    width: 90%;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1000px) {
  footer .footer__navigation {
    width: 70%;
  }
}
footer .footer__navigation .footer__navigation__item {
  margin-bottom: 2.25rem;
}
@media only screen and (min-width: 768px) {
  footer .footer__navigation .footer__navigation__item {
    margin-bottom: 0;
  }
}
footer .footer__navigation .footer__navigation__item .footer__navigation__link {
  color: #595757;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 0.2 cubic-bezier(0.165, 0.84, 0.44, 1);
  font-size: 1.125rem;
  line-height: 1.5;
}
footer
  .footer__navigation
  .footer__navigation__item
  .footer__navigation__link:hover,
footer
  .footer__navigation
  .footer__navigation__item
  .footer__navigation__link:focus {
  color: #1f5a7a;
}
@media only screen and (min-width: 768px) {
  footer
    .footer__navigation
    .footer__navigation__item
    .footer__navigation__link {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 768px) {
  footer .footer__navigation .footer__navigation__item:last-child {
    margin-right: 0;
  }
}
footer .footer__content,
footer .footer__footer {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  footer .footer__content {
    display: flex;
    margin-top: 3.25rem;
    margin-bottom: 3.25rem;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1000px) {
  footer .footer__content {
    margin-bottom: 0;
    margin-top: 3.25rem;
    margin-bottom: 3.375rem;
  }
}
footer .footer__footer {
  text-align: center;
}
@media only screen and (min-width: 768px) {
  footer .footer__footer {
    text-align: left;
  }
}
footer .footer__footer .footer__footer__link {
  color: #595757;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 1rem;
  line-height: 1.5;
}
@media only screen and (min-width: 768px) {
  footer .footer__footer .footer__footer__link {
    margin-bottom: 0;
    font-size: 0.9rem;
    line-height: 1.5;
  }
}
footer .footer__credit {
  width: 100%;
  text-align: center;
  color: #adadad;
  font-family: 'haboro-soft', 'Helvetica', sans-serif;
  padding-left: 0.5rem;
  font-size: 0.9rem;
  line-height: 1.5;
}
@media only screen and (min-width: 768px) {
  footer .footer__credit {
    text-align: left;
  }
}
footer .footer__credit p {
  margin-bottom: 0;
}
</style>
