<script>
import { trapFocus, removeTrapFocus } from '$lib/trapFocus';
import { page } from '$app/stores';
import { onMount } from 'svelte';

export let main_nav;

let navLinks = main_nav.items;

let container;

//
// Menu Drawer JS
//
let navIsOpen = false;
let toggleText = 'Open Menu';
let drawerMenuContainer;
let menuDrawerToggle;
let showNavItems = false;
let isSupportPage = false;

$: isSupportPage = pathName.includes('support');

$: navIsOpen;
$: toggleText;
$: showNavItems;

$: pathName = $page.url.pathname;

let toggleMenuDrawer = function (event) {
  navIsOpen = !navIsOpen;

  if (navIsOpen) {
    openMenuDrawer(event, drawerMenuContainer);
  } else {
    event.preventDefault();
    closeMenuDrawer(event, menuDrawerToggle);
  }
};

let openMenuDrawer = function (event, elementToFocus = false) {
  // drawerMenuContainer.querySelector('aside').style.height = `calc(101vh - ${
  //   document.querySelector('header').clientHeight + 'px'
  // })`;
  // drawerMenuContainer.querySelector('aside').style.top =
  //   document.querySelector('header').clientHeight + 1 + 'px';
  setTimeout(() => {
    showNavItems = !showNavItems;
  }, 100);
  trapFocus(elementToFocus);
};

let closeMenuDrawer = function (event, elementToFocus = false) {
  navIsOpen = navIsOpen ? !navIsOpen : !!navIsOpen;
  setTimeout(() => {
    showNavItems = !showNavItems;
  }, 0);
  removeTrapFocus(elementToFocus);
  drawerMenuContainer.querySelector('aside').removeAttribute('style');
};

let onKeyUp = (event) => {
  if (event.code.toUpperCase() !== 'ESCAPE') return;

  const openDetailsElement = event.target.closest('details[open]');
  if (!openDetailsElement) return;

  openDetailsElement === drawerMenuContainer
    ? closeMenuDrawer(event, menuDrawerToggle)
    : closeSubMenu(openDetailsElement);
};

onMount(async () => {
  await import('$lib/sticky-header');

  container.querySelectorAll('a').forEach((event) => {
    event.addEventListener('click', (e) => {
      setTimeout(() => {
        closeMenuDrawer(e);
      }, 400);
    });
  });
});
</script>

<sticky-header class="header-container" bind:this={container}>
  <header
    class:is-open={navIsOpen}
    class:is-support={isSupportPage}
    bind:this={drawerMenuContainer}
  >
    <div class="header__inner">
      <div class="header__logo" data-ui-component="header-logo">
        <h1>
          <a href="/" rel="internal" title="Go to Varo Home">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="Layer_1"
              x="0"
              y="0"
              version="1.1"
              viewBox="0 0 420 160"
              xml:space="preserve"
            >
              <title>Varo</title>
              <path
                d="M6.2 42.9c-1-2.1.3-4.2 2.7-4.2h21.4c1.3 0 2.4 1 2.7 1.8l26.6 58.8h1l26.6-58.8c.3-.8 1.5-1.8 2.7-1.8h21.4c2.4 0 3.7 2.1 2.7 4.2L63.5 151.4c-.5 1-1.5 1.8-2.7 1.8h-1.6c-1.3 0-2.3-.8-2.7-1.8L6.2 42.9zM322.2 38.7c-22.6 0-41.9 12.7-51.5 31.4-2.5-16.8-17.1-29.8-34.8-29.8h-48.2c-1.8 0-3.1 1.4-3.1 3.1v88.8c0 1.3-1.7 1.6-2.2.5l-42.9-92.2c-.5-1-1.6-1.8-2.7-1.8h-1.6c-1.1 0-2.3.8-2.7 1.8L82.1 148.9c-1 2.1.3 4.2 2.7 4.2h17.7c3.2 0 5-1.9 5.8-3.9l5.5-12.2c.2-.4.6-.7 1.1-.7h42.3c.5 0 .9.3 1.1.7l5.5 12c1.5 3.1 2.7 4 5.6 4h36.3c2.3 0 4.2-1.9 4.2-4.2v-38.7c0-.7.5-1.2 1.2-1.2h9.2c.4 0 .9.3 1.1.6l21.1 42.1c.3.6 1.3 1.5 2.6 1.5h21.4c2.7 0 3.9-2.6 2.7-4.7l-21.5-39.8c-.3-.6-.1-1.4.6-1.6 6.5-2.8 12-7 15.9-12.2 0 .7-.1 1.4-.1 2.1 0 32.2 25.6 57.9 57.9 57.9 32.2 0 58-25.6 58-57.9s-25.6-58.2-57.8-58.2zm-175.7 75.7h-20.8c-.9 0-1.4-.9-1.1-1.7l11.3-24.9h.2l11.5 24.9c.3.9-.3 1.7-1.1 1.7zm87.4-25.9h-22.7c-.7 0-1.2-.5-1.2-1.2V64.5c0-.7.5-1.2 1.2-1.2h22.7c6.6 0 12.2 5.5 12.2 12.2s-5.6 13-12.2 13zm88.3 40.5c-17.6 0-32.1-14.5-32.1-32.1 0-17.7 14.5-32.4 32.1-32.4 17.7 0 32.2 14.7 32.2 32.4 0 17.5-14.5 32.1-32.2 32.1zM391.1 146h-2c-.2 0-.4-.2-.4-.4v-1.5c0-.2.2-.4.4-.4h7.5c.2 0 .4.2.4.4v1.5c0 .2-.2.4-.4.4h-2.1c-.2 0-.4.2-.4.4v6.4c0 .2-.2.4-.4.4h-1.9c-.2 0-.4-.2-.4-.4v-6.4c.1-.2-.1-.4-.3-.4zm7.7-2.3h2.2c.1 0 .3.1.3.2l1.8 2.9c.2.2.5.2.7 0l1.8-2.9c.1-.1.2-.2.3-.2h2.2c.2 0 .4.2.4.4v8.7c0 .2-.2.4-.4.4h-1.8c-.2 0-.4-.2-.4-.4v-5.2l-2.4 3.8h-.1l-2.4-3.8v5.2c0 .2-.2.4-.4.4h-1.8c-.2 0-.4-.2-.4-.4v-8.7c0-.2.2-.4.4-.4z"
                style="fill:#09638a;"
              />
              <g>
                <path
                  d="M392.1 102.3c-2.6 0-4.6-2-4.7-4.6-1.1-35.7-29.8-64.5-65.4-65.6-2.6-.1-4.6-2.1-4.6-4.7V10c0-2.6 2.1-4.7 4.7-4.7 53.2 1.1 91.1 39 92.1 92.3 0 1.3-.5 2.5-1.3 3.4-.9.9-2.1 1.4-3.4 1.4h-17.4v-.1zM321.5 102.3c-2.3 0-4.2-1.9-4.2-4.2V75.9c0-2.2 1.8-4 3.9-4h.2c14.1.8 25.3 12.1 26.1 26.2.1 1.1-.3 2.1-1.1 2.9-.7.8-1.8 1.2-2.9 1.2l-22 .1z"
                  style="fill:#00abe8;"
                />
              </g>
            </svg>
          </a>
        </h1>
      </div>

      <button
        class="header__nav-toggle"
        data-ui-component="header-nav-toggle"
        aria-expanded={navIsOpen}
        class:is-active={navIsOpen}
        bind:this={menuDrawerToggle}
        on:click={toggleMenuDrawer}
      >
        <span aria-hidden="true" class="header__nav-toggle-icon" />
        <span class="visuallyHidden" data-toggle-text>
          {#if navIsOpen}
            Close Menu
          {:else}
            Open Menu
          {/if}
        </span>
      </button>
    </div>

    <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
    <aside
      class="nav"
      on:keyup={onKeyUp}
      data-ui-component="header-nav"
      class:menu-closing={!navIsOpen}
    >
      <nav aria-label="Main navigation">
        <div class="nav__logo">
          <a href="/" rel="internal" title="Go to Varo Home">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="Layer_1"
              x="0"
              y="0"
              version="1.1"
              viewBox="0 0 420 160"
              xml:space="preserve"
            >
              <title>Varo</title>
              <path
                d="M6.2 42.9c-1-2.1.3-4.2 2.7-4.2h21.4c1.3 0 2.4 1 2.7 1.8l26.6 58.8h1l26.6-58.8c.3-.8 1.5-1.8 2.7-1.8h21.4c2.4 0 3.7 2.1 2.7 4.2L63.5 151.4c-.5 1-1.5 1.8-2.7 1.8h-1.6c-1.3 0-2.3-.8-2.7-1.8L6.2 42.9zM322.2 38.7c-22.6 0-41.9 12.7-51.5 31.4-2.5-16.8-17.1-29.8-34.8-29.8h-48.2c-1.8 0-3.1 1.4-3.1 3.1v88.8c0 1.3-1.7 1.6-2.2.5l-42.9-92.2c-.5-1-1.6-1.8-2.7-1.8h-1.6c-1.1 0-2.3.8-2.7 1.8L82.1 148.9c-1 2.1.3 4.2 2.7 4.2h17.7c3.2 0 5-1.9 5.8-3.9l5.5-12.2c.2-.4.6-.7 1.1-.7h42.3c.5 0 .9.3 1.1.7l5.5 12c1.5 3.1 2.7 4 5.6 4h36.3c2.3 0 4.2-1.9 4.2-4.2v-38.7c0-.7.5-1.2 1.2-1.2h9.2c.4 0 .9.3 1.1.6l21.1 42.1c.3.6 1.3 1.5 2.6 1.5h21.4c2.7 0 3.9-2.6 2.7-4.7l-21.5-39.8c-.3-.6-.1-1.4.6-1.6 6.5-2.8 12-7 15.9-12.2 0 .7-.1 1.4-.1 2.1 0 32.2 25.6 57.9 57.9 57.9 32.2 0 58-25.6 58-57.9s-25.6-58.2-57.8-58.2zm-175.7 75.7h-20.8c-.9 0-1.4-.9-1.1-1.7l11.3-24.9h.2l11.5 24.9c.3.9-.3 1.7-1.1 1.7zm87.4-25.9h-22.7c-.7 0-1.2-.5-1.2-1.2V64.5c0-.7.5-1.2 1.2-1.2h22.7c6.6 0 12.2 5.5 12.2 12.2s-5.6 13-12.2 13zm88.3 40.5c-17.6 0-32.1-14.5-32.1-32.1 0-17.7 14.5-32.4 32.1-32.4 17.7 0 32.2 14.7 32.2 32.4 0 17.5-14.5 32.1-32.2 32.1zM391.1 146h-2c-.2 0-.4-.2-.4-.4v-1.5c0-.2.2-.4.4-.4h7.5c.2 0 .4.2.4.4v1.5c0 .2-.2.4-.4.4h-2.1c-.2 0-.4.2-.4.4v6.4c0 .2-.2.4-.4.4h-1.9c-.2 0-.4-.2-.4-.4v-6.4c.1-.2-.1-.4-.3-.4zm7.7-2.3h2.2c.1 0 .3.1.3.2l1.8 2.9c.2.2.5.2.7 0l1.8-2.9c.1-.1.2-.2.3-.2h2.2c.2 0 .4.2.4.4v8.7c0 .2-.2.4-.4.4h-1.8c-.2 0-.4-.2-.4-.4v-5.2l-2.4 3.8h-.1l-2.4-3.8v5.2c0 .2-.2.4-.4.4h-1.8c-.2 0-.4-.2-.4-.4v-8.7c0-.2.2-.4.4-.4z"
                style="fill:#09638a;"
              />
              <g>
                <path
                  d="M392.1 102.3c-2.6 0-4.6-2-4.7-4.6-1.1-35.7-29.8-64.5-65.4-65.6-2.6-.1-4.6-2.1-4.6-4.7V10c0-2.6 2.1-4.7 4.7-4.7 53.2 1.1 91.1 39 92.1 92.3 0 1.3-.5 2.5-1.3 3.4-.9.9-2.1 1.4-3.4 1.4h-17.4v-.1zM321.5 102.3c-2.3 0-4.2-1.9-4.2-4.2V75.9c0-2.2 1.8-4 3.9-4h.2c14.1.8 25.3 12.1 26.1 26.2.1 1.1-.3 2.1-1.1 2.9-.7.8-1.8 1.2-2.9 1.2l-22 .1z"
                  style="fill:#00abe8;"
                />
              </g>
            </svg>
          </a>
        </div>

        <ul class="nav__inner">
          {#each navLinks as navItem, index}
            <li class="nav__item" data-link={navItem.text}>
              {#if navItem.navigationItemUrl.linkType == 'internal'}
                <a
                  rel="internal"
                  class="nav__link"
                  href="/{navItem.navigationItemUrl.internalLink.handle
                    .current}"
                  title="Go to {navItem.text}"
                >
                  {navItem.text}
                </a>
              {/if}

              {#if navItem.navigationItemUrl.linkType == 'external'}
                <a
                  href={navItem.navigationItemUrl.href}
                  target={navItem.navigationItemUrl.blank ? '_blank' : null}
                  rel="external"
                  class="nav__link"
                  data-link={navItem.text.toLowerCase()}
                  title="Go to external link at {navItem.navigationItemUrl
                    .href}"
                >
                  {navItem.text}
                </a>
              {/if}
            </li>
          {/each}
        </ul>
      </nav>
    </aside>
  </header>
</sticky-header>

<style>
@-webkit-keyframes show-fixed-nav {
  0% {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes show-fixed-nav {
  0% {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
@-webkit-keyframes hide-fixed-nav {
  0% {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
@keyframes hide-fixed-nav {
  0% {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

header.is-support {
  max-width: 94rem;
}
.header-container {
  width: 100%;
  top: 0;
  z-index: 5;
  position: fixed;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

:global(.header-container.is-fixed .header__logo) {
  opacity: 0;
}
@media only screen and (min-width: 768px) {
  :global(.header-container.is-fixed .header__logo) {
    opacity: 1;
  }
}
@media only screen and (min-width: 768px) {
  :global(.header-container) {
    background-color: transparent;
  }
  :global(.header-container.is-fixed) {
    box-shadow: -2px -3px 14px -6px #000;
    background-color: #fff;
  }
  :global(.header-container.is-fixed .header__inner) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  :global(.header-container.is-fixed .header__logo) {
    width: 6.5rem;
  }
}
@-webkit-keyframes show-header-logo {
  0% {
    opacity: 0;
  }
  1% {
    position: fixed;
    opacity: 0;
  }
  to {
    opacity: 1;
    position: fixed;
  }
}
@keyframes show-header-logo {
  0% {
    opacity: 0;
  }
  1% {
    position: fixed;
    opacity: 0;
  }
  to {
    opacity: 1;
    position: fixed;
  }
}
header {
  position: relative;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-between;
  height: 5rem;
}
@media only screen and (min-width: 768px) {
  header {
    height: 4rem;
    background-color: transparent;
  }
}
@media only screen and (min-width: 1000px) {
  header {
    max-width: 80rem;
    margin-right: auto;
    margin-left: auto;
    right: 0;
    left: 0;
    height: auto;
  }
}
header .header__inner {
  padding: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  position: relative;
  width: 100%;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
@media only screen and (min-width: 768px) {
  header .header__inner {
    width: auto;
    padding: 1rem;
  }
}
@media only screen and (min-width: 1000px) {
  header .header__inner {
    padding-left: 2.25rem;
  }
}
header .header__logo {
  width: 6rem;
  height: auto;
  z-index: 3;
  top: 0;
  position: relative;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
@media only screen and (min-width: 768px) {
  header .header__logo {
    width: 6.5rem;
    width: 7rem;
  }
}
header .header__logo h1 {
  margin-bottom: 0;
}
header .header__logo a,
header .header__logo svg {
  width: 100%;
}
header .header__nav-toggle {
  border-radius: 100%;
  width: 3.75rem;
  height: 3.75rem;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  position: fixed;
  right: 0.75rem;
  background-color: #fff;
  box-shadow: 0 0 13px -6px rgba(28, 87, 141, 0.6);
}
@media only screen and (min-width: 768px) {
  header .header__nav-toggle {
    display: none;
  }
}
header .header__nav-toggle .header__nav-toggle-icon {
  position: relative;
  width: 1.875rem;
  display: block;
  height: 3px;
  background-color: #0491bc;
  top: 0;
  bottom: 0;
  position: absolute;
  margin: auto;
}
header .header__nav-toggle .header__nav-toggle-icon:after,
header .header__nav-toggle .header__nav-toggle-icon:before {
  content: ' ';
  height: 3px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 100px;
  background-color: #0491bc;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
header .header__nav-toggle .header__nav-toggle-icon:after {
  top: -8px;
}
header .header__nav-toggle .header__nav-toggle-icon:before {
  bottom: -8px;
}
header .header__nav-toggle:active,
header .header__nav-toggle:focus {
  outline: 0;
}
header .header__nav-toggle.is-active .header__nav-toggle-icon {
  background-color: #fff;
}
header .header__nav-toggle.is-active .header__nav-toggle-icon:active,
header .header__nav-toggle.is-active .header__nav-toggle-icon:focus {
  outline: 0;
}
header .header__nav-toggle.is-active .header__nav-toggle-icon:after {
  top: 0;
  transform: rotate(45deg);
}
header .header__nav-toggle.is-active .header__nav-toggle-icon:before {
  bottom: 0;
  transform: rotate(-45deg);
}
header .nav__logo {
  width: 6rem;
  height: auto;
  z-index: 3;
  top: 1.08rem;
  left: 0.75rem;
  position: absolute;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
@media only screen and (min-width: 768px) {
  header .nav__logo {
    display: none;
  }
}
header .nav__logo h1 {
  margin-bottom: 0;
}
header .nav__logo a,
header .nav__logo svg {
  width: 100%;
}
header.is-open .nav {
  display: block;
  background-color: #fff;
}
header.is-closed .header__logo {
  -webkit-animation: hide-header-logo 0.4s cubic-bezier(0.165, 0.84, 0.44, 1)
    forwards;
  animation: hide-header-logo 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
header.is-closed .nav {
  display: none;
}
@media only screen and (min-width: 768px) {
  header.is-closed .nav {
    display: block !important;
  }
}
header .nav {
  position: fixed;
  background-color: transparent;
  width: 100%;
  padding-top: 4.5rem;
  right: 0;
  display: flex;
  height: 100%;
  top: 0;
  flex-direction: column;
  z-index: 4;
  display: none;
}
@media only screen and (min-width: 768px) {
  header .nav {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 0;
    top: 0;
    right: 0;
    height: auto;
    align-items: center;
    justify-content: center;
  }
}
header .nav__inner {
  display: flex;
  flex-direction: column;
  padding: 1.125rem 1.125rem 1.125rem 5%;
  padding-top: 2rem;
}
@media only screen and (min-width: 768px) {
  header .nav__inner {
    flex-direction: row;
    text-align: right;
    justify-content: flex-end;
    padding: 0 0 0 0;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
header .nav__link {
  color: var(--primary-color);
  text-decoration: none;
  text-transform: capitalize;
  font-family: 'Be Vietnam Pro', Helvetica, sans-serif;
  font-weight: 700;
  letter-spacing: 0.2px;
  font-size: 2rem;
  line-height: 1.25;
}
@media only screen and (min-width: 768px) {
  header .nav__link {
    font-size: 1rem;
    line-height: 1.5;
  }
}
header .nav__link:focus,
header .nav__link:hover {
  color: #0491bc;
}
header .nav__item {
  margin-bottom: 1.125rem;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  header .nav__item {
    margin-bottom: 0;
    margin-right: 3%;
  }
}

@media only screen and (min-width: 1000px) {
  header .nav__item {
    margin-bottom: 0;
    margin-right: 5%;
  }
}

@media only screen and (min-width: 768px) {
  header [data-link='download varo'] {
    order: 3;
    margin-right: 0;
  }
  header [data-link='download varo'] a {
    height: 2.375rem;
    width: 9.375rem;
    transition: none;
    color: #fff;
    cursor: pointer;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    font-weight: 400;
    padding: 0;
    display: block;
    text-decoration: none;
    letter-spacing: 0.5px;
    border-radius: 48px;
    color: var(--primary-color);
    background-color: #fff;
    font-family: 'Be Vietnam Pro', Helvetica, sans-serif;
    font-weight: 700;
    position: relative;
    z-index: 1;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    overflow: hidden;
    display: flex;
    border: 2px solid #cecece;
    box-shadow: 0 4px 20px -9px rgba(0, 0, 0, 0.2);
    font-size: 0.9rem;
    line-height: 1.5;
    margin-bottom: 0;
  }
  header [data-link='download varo'] a .icon {
    width: 2.625rem;
    height: 1.625rem;
  }
  header [data-link='download varo'] a.is-disabled {
    filter: grayscale(100%);
    opacity: 0.4;
    cursor: not-allowed;
  }
  header [data-link='download varo'] a span {
    position: relative;
    z-index: 2;
    top: 0;
    bottom: 0;
    margin: auto;
    padding-bottom: 2px;
  }
  header [data-link='download varo'] a:focus,
  header [data-link='download varo'] a:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 20px -2px rgba(0, 0, 0, 0.1);
  }
  header [data-link='download varo'] a:after {
    background-color: transparent;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  header [data-link='download varo'] a:after,
  header [data-link='download varo'] a:before {
    content: ' ';
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  header [data-link='download varo'] a:before {
    background-color: #0491bc;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    bottom: 0;
    margin: auto;
    z-index: 1;
    transform: scale(0);
    transform-origin: center;
    opacity: 0;
  }
}

button:focus,
button:hover {
  transform: scale(1.08);
}
nav {
  height: 100%;
}

@media screen and (min-width: 1000px) {
  header.is-open .nav {
    background-color: transparent;
  }
}

.nav__item {
  margin-bottom: 1.25rem;
}
.nav__item a {
  font-weight: normal;
  font-size: 1.75rem;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .nav__item {
    margin-right: 3%;
    margin-bottom: 0;
  }
  .nav__item a {
    font-size: 1rem;
  }
  .nav__inner .nav__item:last-child {
    margin-right: 2%;
  }
}

@media screen and (min-width: 768px) {
  :global(header .nav__link[data-link='download varo']) {
    height: 2.375rem;
    width: 9.375rem;
    border-radius: 48px;
    color: #fff !important;
    background-color: var(--secondary-color);
    font-family: 'Be Vietnam Pro', Helvetica, sans-serif;
    position: relative;
    z-index: 1;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 7px 32px -9px rgb(28 87 141 / 50%);
  }

  :global(header .nav__link[data-link='download varo']:hover),
  :global(header .nav__link[data-link='download varo']:focus) {
    color: #fff;
    transform: translateY(-3px);
    box-shadow: 0 8px 24px -2px rgba(18, 92, 128, 0.4);
  }
}
</style>
